import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound404() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("");
    }, 5000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <section className="text-white flex flex-col items-center min-h-[60svh] justify-center">
        <h1 className="text-2xl xl:text-6xl text-center font-bold px-4">Page not found.</h1>
        <p className="flex flex-col mt-2 text-2xl px-4 text-center">Redirecting to Homepage....</p>
        <button className="bg-accentBg py-2 px-4 uppercase rounded-lg mt-4" onClick={() => navigate("")}>
          Home
        </button>
    </section>
  );
}

export default NotFound404;
 