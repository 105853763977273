import axios from "../api/axiosConfig";

export const subscribeViaEmail = async (email) => {
  try {
    const res = await axios.post(
      "/customer",
      {
        email,
      },
      {
        auth: {
          username: "admin",
          password: "12345678",
        },
      }
    );
    alert("Subscribed successfully!!!");
    return res;
  } catch (err) {
    throw err;
  }
};
