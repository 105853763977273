import React, { useEffect } from "react";
import logo from "../assets/images/logo.webp";
import configData from "../config.json";

function AgeVerification({ setIsOldEnough, isOldEnough }) {

  const preloadImages = ()=>{
    const preloadassets = [logo]

    // eslint-disable-next-line array-callback-return
    preloadassets.map((item) => {
      const link = document.createElement('link')
      link.rel = 'preload'
      link.as = 'image'
      link.href = item;
      document.head.appendChild(link)
    })
  }

  useEffect(()=>{
    preloadImages()
  },[])


  return (
    <div className="absolute top-0  w-screen h-dvh bg-black flex z-50 bg-opacity-70 text-white ">
      <div className="bg-[#010101] h-fit m-auto rounded-xl overflow-hidden flex flex-col gap-8 items-center px-8 py-12 justify-center popupAnimation max-sm:mx-8">
        <img
          src={logo}
          alt="logo"
          className="w-28 h-28 aspect-square border-2 border-white rounded-full p-2"
        />
        <h1 className="text-4xl font-bold uppercase max-sm:text-2xl text-center">
          <span>{configData.ageVerification.title}</span>
        </h1>
        <div className="flex flex-col md:flex-row gap-2">
          <button
            className="bg-accentBg font-bold px-5 py-4  uppercase focus-within:outline-none "
            onClick={() => {
              localStorage.setItem("isOldEnough", true);
              setIsOldEnough(true);
            }}
          >
            {"<< Yes >>"}
          </button>
          <button
            className="bg-accentBg font-bold px-5 py-4  uppercase focus-within:outline-none "
            onClick={() => setIsOldEnough(false)}
          >
            {"<< No >>"}
          </button>
        </div>
        {isOldEnough === false && (
          <h1 className="text-2xl font-bold max-sm:text-xl text-center">
            You are <span className="text-accent">not</span> old enough
          </h1>
        )}
        <p className="text-sm text-accent">
          {isOldEnough == null ? (
            <span>
              {configData.ageVerification.subTitle1}
            </span>
          ) : (
            <span>{configData.ageVerification.subTitle2}</span>
          )}
        </p>
      </div>
    </div>
  );
}

export default AgeVerification;
