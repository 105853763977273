// axiosInstance.js
import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/api`,
  timeout: 10000, // Adjust the timeout as needed
  headers: {
    "Content-Type": "application/json",
    // Add any other headers you need
  },
});

export default instance;
