import { useEffect } from "react";

const useWindowResize = (setShowSidebar) => {
  useEffect(() => {
    setShowSidebar(window.innerWidth < 1024);
    const handleResize = () => {
      setShowSidebar(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);
};

export default useWindowResize;
