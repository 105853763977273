import React, { useEffect } from "react";
import beerImage from "../assets/images/beer.webp";
import { ReactComponent as CrossSvg } from "../assets/svgs/cross.svg";
import { subscribeViaEmail } from "../api/subscribeViaEmail";

import configData from "../config.json";

function GetEmailAlerts({ setIsShow }) {
  const preloadImages = () => {
    const preloadassets = [beerImage]

    // eslint-disable-next-line array-callback-return
    preloadassets.map((item) => {
      const link = document.createElement('link')
      link.rel = 'preload'
      link.as = 'image'
      link.href = item;
      document.head.appendChild(link)
    })
  }

  useEffect(() => {
    preloadImages()
  }, [])



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await subscribeViaEmail(e.target.email.value);
      e.target.email.value = "";
      setIsShow(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    // <div className="absolute right-0 bottom-6  w-full px-6 flex">
    <div className="fixed right-5 bottom-4 ml-auto bg-[#6c231c] rounded-xl z-40 flex overflow-hidden select-none justify-between w-[90%] max-w-[400px] text-white popupAnimation">
      <div className="w-[70%] p-6">
        <h1 className="font-bold text-xl">{configData.getEmailAlerts.title}</h1>
        <p className="mt-2 text-sm">
          {configData.getEmailAlerts.subTitle}&nbsp;
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex relative bg-white  w-fit rounded-lg overflow-hidden mt-4">
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              className="pl-4 pr-2 text-sm text-black w-44 focus-within:outline-none "
            />
            <button
              type="submit"
              className="inline-block text-xs h-full bg-accentBg p-2 uppercase m-1 rounded-lg cursor-pointer"
            >
              {configData.getEmailAlerts.buttonLabel}
            </button>
          </div>
        </form>
      </div>
      <img src={beerImage} alt="beerImage" className="w-[30%] " />
      <CrossSvg
        className="absolute top-3 right-3 cursor-pointer w-6 h-6 "
        onClick={() => setIsShow(false)}
      />
    </div>
    // </div>
  );
}

export default GetEmailAlerts;
