import Lottie from "lottie-react";
import BuyBeerAnimationData from "../assets/lottieFiles/beer-loader.json";

const PageLoader = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black z-50">
            <Lottie animationData={BuyBeerAnimationData} loop={true} className="w-4/12 xl:w-2/12" />
        </div>
    );
};

export default PageLoader;
