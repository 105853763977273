import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useWindowResize from "./hooks/useWindowResize";

import AgeVerification from "./components/AgeVerification";
import GetEmailAlerts from "./components/GetEmailAlerts";
import { FilterProvider } from "./contexts/FilterContext";
import NavBarComp from "./components/NavBarComp";
import PageLoader from "./components/PageLoader";
import NotFound404 from "./components/404";

// LAZY LOADING ROUTES
const Home = React.lazy(() => import("./pages/Home"))
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Faqs = React.lazy(() => import("./pages/Faqs"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const BlogPage = React.lazy(() => import('./pages/BlogPage'));
const Footer = React.lazy(() => import('./components/Footer'));


function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  useWindowResize(setShowSidebar); // for switching between mobile and desktop view of sidebars
  const [isOldEnough, setIsOldEnough] = useState(
    localStorage.getItem("isOldEnough")
  );
  const [showGetAlertsPopup, setShowGetAlertsPopup] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowGetAlertsPopup(true), 5000); //show popup after 5 seconds
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Suspense fallback={<PageLoader />}>

          <FilterProvider>

            <Router>
              <NavBarComp mode={"general"} />

              <main className="flex-grow">
                <Routes>
                  <Route path="/" element={<Home showSidebar={showSidebar} setShowSidebar={setShowSidebar} />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blogs/:permalink" element={<BlogPage />} />
                  <Route path="/faqs" element={<Faqs />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/*" element={<NotFound404 />} />
                </Routes>
              </main>

              {!isOldEnough && (
                <AgeVerification
                  isOldEnough={isOldEnough}
                  setIsOldEnough={setIsOldEnough}
                />
              )}
              {showGetAlertsPopup && (
                <GetEmailAlerts setIsShow={setShowGetAlertsPopup} />
              )}

              <Footer />          
            </Router>
          </FilterProvider>
      </Suspense>
    </div>
  );
}

export default App;
