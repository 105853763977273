import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Logo from "../assets/images/logo.webp"
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";




const navItems = [
    {
        title: "HOME",
        link: "/"
    },
    {
        title: "ABOUT US",
        link: "/about-us"
    }, {
        title: "BLOGS",
        link: "/blogs"
    }, {
        title: "FAQS",
        link: "/faqs"
    }, {
        title: "CONTACT US",
        link: "/contact-us"
    },
]


const NavBarComp = ({
    mode
}) => {

    const { pathname } = useLocation()
    const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false)

    const preloadImages = () => {
        const preloadassets = [Logo]

        // eslint-disable-next-line array-callback-return
        preloadassets.map((item) => {
            const link = document.createElement('link')
            link.rel = 'preload'
            link.as = 'image'
            link.href = item;
            document.head.appendChild(link)
        })
    }

    useEffect(() => {
        preloadImages()
    }, [])


    return (
        <>


            {mode === "general" && pathname !== "/" && (
                <nav className={`flex items-center px-3 py-5 justify-end lg:justify-center relative border-b-4 border-b-accent `}>

                    <div className=" absolute top-0 left-2 sm:left-4 w-[4.5rem] h-[4.5rem] ">
                        <Link to={"/"}>
                            <img src={Logo} alt="buybeer brand logo" />
                        </Link>
                    </div>

                    <ul className="hidden lg:flex items-center space-x-8 px-1 text-lg">
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <Link to={item.link}>
                                    <p className={`cursor-pointer ${pathname === item.link ? 'text-accent font-bold' : 'text-white font-medium'} hover:scale-105 transition-all ease-in`}>{item.title}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <GiHamburgerMenu
                        size={30}
                        color="white"
                        className="cursor-pointer visible lg:hidden top-0 right-0 "
                        onClick={() => setMobileNavbarOpen((prev) => !prev)}
                    />


                </nav>
            )}

            {mode === "mainTable" && pathname === "/" && (
                <nav className={`flex items-center justify-center relative`}>
                    <ul className="ml-4 hidden lg:visible lg:flex items-center space-x-4 xl:space-x-8 px-1 text-base">
                        {navItems.map((item, index) => (
                            <li key={index}>
                                <Link to={item.link}>
                                    <p className={`cursor-pointer ${pathname === item.link ? 'text-accent font-bold' : 'text-white font-medium'} hover:scale-105 transition-all ease-in`}>{item.title}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>



                    <GiHamburgerMenu
                        size={30}
                        className="cursor-pointer visible lg:hidden"
                        onClick={() => setMobileNavbarOpen((prev) => !prev)}
                    />


                </nav >
            )}





            {/* MOBILE NAVITEMS SIDEBAR  */}
            <div className={` fixed z-50 top-0 -right-5 bg-black w-[300px]  ${mobileNavbarOpen ? '-translate-x-[20px] ' : 'translate-x-[400px] '
                } min-h-screen transition-transform duration-150 flex flex-col gap-10 lg:hidden`}
                aria-hidden={!mobileNavbarOpen}>

                <div className="flex items-center justify-between border-b-accent border-b-2 pr-5 pl-2 py-2">
                    <Link to={"/"}>
                        <img src={Logo} alt="buybeer brand logo " className="w-24 h-24" />
                    </Link>
                    <IoClose
                        size={35}
                        className="mx-1 cursor-pointer"
                        color="white"
                        onClick={() => setMobileNavbarOpen((prev) => !prev)}
                    />
                </div>

                <ul className="flex flex-col items-start space-y-10 pl-5 text-base">
                    {navItems.map((item, index) => (
                        <li key={index}>
                            <Link to={item.link} onClick={() => setMobileNavbarOpen(false)}>
                                <p className={`font-medium text-2xl cursor-pointer ${pathname === item.link ? 'text-accent' : 'text-white'} hover:scale-105 transition-all ease-in`}>{item.title}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>



        </>
    )
}
export default NavBarComp