export const filtersDefaultValue = {
  is_out_of_stock: false,
  title: "",
  page: 1,
};

export const currency = "£";

export const footerLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Blogs",
    link: "/blogs",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "FAQs",
    link: "/faqs",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    name: "Contact Us",
    link: "/contact-us",
  },
];

export const sortingOptions = [
  {
    value: "-sales_rank",
    label: "Popularity",
  },
  {
    value: "price_per_litre",
    label: "Price per Litre (Low - High)",
  },
  {
    value: "-price_per_litre",
    label: "Price per Litre (High - Low)",
  },
  {
    value: "price",
    label: "Price(Low - High)",
  },
  {
    value: "-price",
    label: "Price (High - Low)",
  },
  {
    value: "volume",
    label: "Volume (Low - High)",
  },
  {
    value: "-volume",
    label: "Volume (High - Low)",
  },
  {
    value: "alcohol_content",
    label: "Alcohol content (Low - High)",
  },
  {
    value: "-alcohol_content",
    label: "Alcohol content (High - Low)",
  },
  {
    value: "rating",
    label: "Rating (Low - High)",
  },
  {
    value: "-rating",
    label: "Rating (High - Low)",
  },
];
